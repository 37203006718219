import React, { useEffect, useRef } from 'react';
import { getCursorFromDocumentIndex } from 'gatsby-source-prismic-graphql';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import NewsList from 'components/_grid/News';

export default props => {
  //Required check for no data being returned
  const newsroom = props.data.prismic.allNewsrooms.edges[0].node;

  if (!newsroom) return null;

  const limit = 12;
  const didMountRef = useRef(false);
  const [page, setPage] = React.useState(-1);
  const [data, setData] = React.useState(props.data.prismic.allPosts);
  const [posts, setPosts] = React.useState(data.edges);

  const onLoadMore = () => {
    setPage(page + limit);
  };

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    props.prismic
      .load({ variables: { after: getCursorFromDocumentIndex(page) } })
      .then(res => {
        setData(res.data.allPosts);
        setPosts(a => a.concat(res.data.allPosts.edges));
      });
  }, [props, page]);

  return (
    <Layout headerStyle={'dark'}>
      <SEO
        title={newsroom.social_title || 'Newsroom'}
        description={newsroom.social_description ? newsroom.social_description : null}
        image={newsroom.social_image ? newsroom.social_image.url : null}
      />

      <Hero
        title={newsroom.introduction_heading}
        variant='condensed'
      />

      <NewsList
        news={posts}
        hasMore={data.pageInfo.hasNextPage}
        onLoadMore={onLoadMore}
        variant='newsroom'
      />
    </Layout>
  )
}

export const query = graphql`
  query getPosts($first: Int = 12, $after: String) {
    prismic {
      allNewsrooms {
        edges {
          node {
            introduction_heading
            introduction_content
            social_title
            social_description
            social_image
          }
        }
      }
      allPosts(first: $first, after: $after, sortBy: publish_date_DESC) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            _meta {
              uid
            }
            title
            type {
              ... on PRISMIC_Type {
                name
              }
            }
            feature_image
          }
        }
      }
    }
  }
`;
